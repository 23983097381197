<template>
  <div class="flex items-baseline" :class="baseClasses">
    <div class="items-center">
      <span class="flex-auto" :class="titleClasses">
        {{ title }}
        <span :class="subtitleClasses">{{ subtitle }}</span>
      </span>
      <button v-if="pictogram" class="ml-2" @click="$emit('pictogramClicked')">
        <DynamicPictogram :name="pictogram" size="sm" />
      </button>
    </div>

    <DynamicPictogram v-if="loading" class="ml-auto" name="spinner" spin />
    <span
      v-else
      class="flex-auto text-right"
      :class="[priceClasses, { 'text-primary-default': showBasePrice }]"
      :data-test="dataTest"
    >
      {{ $formatPrice(price as number) }}

      <span v-if="showBasePrice" class="text-black/54 line-through">
        {{ $formatPrice(basePrice as number) }}
      </span>
    </span>
  </div>
</template>

<script lang="ts" setup>
defineEmits(['pictogramClicked']);

const props = defineProps({
  title: {
    type: String,
    required: true,
  },
  price: {
    type: [Number, String],
    required: true,
  },
  basePrice: {
    type: [Number, String],
    default: '',
  },
  pictogram: {
    type: String,
    default: '',
  },
  titleClasses: {
    type: String,
    default: '',
  },
  priceClasses: {
    type: String,
    default: '',
  },
  loading: {
    type: Boolean,
    required: false,
  },
  dataTest: {
    type: String,
    default: '',
  },
  subtitle: {
    type: String,
    default: '',
  },
  subtitleClasses: {
    type: String,
    default: '',
  },
  baseClasses: {
    type: String,
    default: '',
  },
});

const showBasePrice = computed(
  () => props.basePrice && props.price < props.basePrice
);
</script>
